select {
  font-size: 0.9rem;
  padding: 6px 25px;
  background: #ffffff;
  /* stroke */
  border: 1px solid #e6ebf1;
  border-radius: 8px;
}
/* option:hover { 
    line-height: 20px;
    background: #E6EAF0;
} */

.container {
  width: 100%;
  height: auto;
  padding: 16px 10px 0 16px;
  display: grid;
  grid-template-columns:repeat(3,1fr);
  grid-template-rows: auto;
  gap: 21px;
  grid-template-areas:
    "A A B"
    "C C C "
    "D E E"
    "F E E"
    "F G G"
    "F G G"
}

.bottomContainer{
  padding: 16px 10px 0 16px;
  display:grid;
  grid-template-columns: repeat(2,1fr);
  gap:16px
}


.item1 {
  grid-area: A;
}
.item2 {
  grid-area: B;
}
.item3 {
  grid-area: C;
}
.item4 {
  grid-area: D;
}
.item5 {
  grid-area: E;
 
}
.item6 {
  grid-area: F; 
}
.item7 {
  grid-area: G;
}
/* .item8 {
  grid-area: H;
}
.item9 {
  grid-area: I;
}
.item10 {
  grid-area: J;
}
.item11 {
  grid-area: K;
} */

/* small screens */

/* Medium screens */
@media only screen and (min-width: 421px) and (max-width: 786px) {
  .container {
    grid-template-areas:
      "A A A "
      "B B B "
      "C C C "
      "D D D "
      "E E E "
      "F F F "
      "G G G ";
  }
  .bottomContainer{
    grid-template-columns: repeat(1,1fr);
  }
}
