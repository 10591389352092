.personality-card {
  min-height: 300px;
  margin: 15px 15px;
}
.personality-card-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #1b75bc;
}
.add-button {
  padding: 0px 20px 0px 20px;
  background: rgba(221, 253, 182, 0.15);
  border: 1px solid #e6ebf1;
  border-radius: 7px;
  margin-left: 17px;
}
.tag {
  padding: 5px 12px 5px 12px;
  background: rgba(221, 253, 182, 0.15);
  border-radius: 7px;
  border: none;
}
.tag-list {
  margin-top: 20px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.chat-game-sucess-msg {
  border: 1px solid red;
  background: #00ab6f;
  border: none;
  margin-top: 8.5vh;
  width: 100%;
  margin-left: 18%;
}

.chat-game-card{
    min-height: 300px;
    padding: 30px 22px;
}
.dashed-border-img{
    padding: 28px 30px;
border: 2px dashed #E6EBF1;
border-radius: 12px;
}
.chat-game-input{
    width: 500px;
    height:45px;
    font-weight:600;
    font-size:18px;
    border: 1px solid #E6EBF1;
    border-radius: 8px;
}
.chat-game-option-input{
  width: 175px;
    height:40px;
    font-weight:500;
    text-align: center;
    font-size:16px;
    border: 1px solid #E6EBF1;
    border-radius: 8px;
}
.chat-game-img-container{
    display: flex;
    width: 70%;
    margin-top: 30px;
    margin-bottom: 30px;
    gap:10%;
    /* align-items: flex-end; */
    /* justify-content: space-between; */
} 



/*alert box*/
.ant-alert-success .ant-alert-icon {
   color: white;
 }
  
 .ant-alert-content {
   max-width:max-content;
   text-align: center;
   width: 10%;
 }
 .ant-alert-message {
   color:White;
 }
 .ant-alert-close-icon{
   filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(66deg) brightness(110%) contrast(101%);
 }
 .ant-alert-banner {
  display: flex;
  justify-content: center;
 }
.containers{
  display: flex;
  flex-direction: column;
}