.chat-container{
    width: 100%;
    height: 100%;
    display: flex;
}

.chat-left{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 40%;
    max-height: 855px;
}
.chat-list{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 450px;
    max-height: 450px;
    /* min-height: 460px; */
    overflow-y: scroll;
}
.single-chat{
    width: 100%;
    display: flex;
    min-height: 80px;
    padding:5px 10px;
    cursor: pointer;
}
.chat-user-details{
    width: 100%;
    display: flex;
    justify-content: space-between;
    
}
.ex{
margin-left: 10px;
    display: flex;
    flex-direction: column; 
    width: 80%;
}
.ex1{
    margin-left: 10px;
        display: flex;
        flex-direction: column; 
        align-items: self-end;
        /* width: 50%; */
    }
.chat-user-name{
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 24px;
}
.chat-user-last-msg{
    display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
margin-top: -15px;
color: #8A8A8A;
/* max-width: 200px;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap; */
}
.chat-user-msg-time{
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 12px;
/* line-height: 18px; */
color: rgba(39, 44, 53, 0.6);
}
.chat-user-msg-count{
margin-right: 5px;
font-weight: 400;
font-size: 12px;
line-height: 18px;
width: 18px;
height: 18px;
background: #4DAE47;
border-radius: 100px;
color: white;
text-align: center;
}

.inbox-header{
    width: 100%;
    height: 60px;
    display: flex;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
}

.inbox-title{
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 20px;
color: #272C35;
}

.search-btn{
    width: 40px;
    height: 32px;
    background: #FFFFFF;
border: 1px solid #E6EBF1;
border-radius: 6px;
font-size: 1.2rem;
padding: 3px 10px;
}

.ver-line{
    border: 1px solid #E6EBF1;
    transform: rotate(0deg);
}
.hor-line{
    border: 1px solid #E6EBF1;
transform: rotate(180deg);
}


.chat-room{
    width: 70%;
    padding: 0px 10px;
}
.chat-room-w100{
    width: 100%;
}
.user-chat-header{
    width: 100%;
    /* border: 1px solid blue; */
}

.chat-profile{
    width: 100%;
    display: flex;
    /* border: 1px solid red; */
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 76px;
}
.chat-user-data{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}
.chat-user-name{
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
}
.chat-user-email{
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
}
.chat-masg{
    min-width: 100%;
    /* max-height: 550px; */
    /* height:550px; */
    height:350px;
    overflow-y: scroll;
}
.chat-input-box{
    /* position: fixed; */
    /* bottom: 10; */
width: 90%;
/* height: 96px; */
min-height: 96px;
padding-bottom: 20px;
margin-left: 5%;

}






/* chat masg css**/
.messages-chat {
    padding: 10px 15px;
  }
  
 .message {
    display:flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
 .message .photo {
      display: block;
      width: 45px;
      height: 45px;
      background: #E6E7ED;
      color: #272C35;
      -moz-border-radius: 50px;
      -webkit-border-radius: 50px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
  }
.text {
    margin: 0 15px;
    background-color: #EDEDED;
    padding: 15px;
    border-radius: 12px;
  }
  
  .text-only {
    margin-left: 45px;
    margin-top: 40px;
  }
  
  .time {
    font-weight: 500;
    font-size: 12px;
    color:#1B75BC;
    margin-bottom:10px;
    margin-left: 67px;
  }
  
  .response-time {
    float: right;
    margin-right: 20px !important;
  }
  
  .response {
    float: right;
    margin-right: 0px !important;
    margin-left:auto; /* flexbox alignment rule */
  }
  
  .response .text {
    background: #1B75BC !important;
    color: #FFFFFF;
  }




/* chat masg css END**/