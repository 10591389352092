@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css?family=Robota');
@import url('https://fonts.googleapis.com/css?family=Inter');
*{
  font-family:"Poppins";
  padding:0;
  margin: 0;
  box-sizing: border-box;
}

/*react quill*/

/*react quill*/
/*Scroll Bar styling*/
::-webkit-scrollbar {
  width: 5px;
  border-radius: 10%;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
/*Scroll edn Bar styling*/















/*anticon ant-menu-item-icon*/
/* Sidebar Styles*/

.ant-menu > .ant-menu-item:hover ,
.ant-menu > .ant-menu-submenu:hover ,
.ant-menu > .ant-menu-item-active,
.ant-menu> .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu> .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
    background-color:#1B75BC1A !important;
} 

 .ant-menu-item:hover .ant-menu-item-icon ,
.ant-menu-submenu:hover .ant-menu-item-icon  ,
 .ant-menu-item-active .ant-menu-item-icon ,
 .ant-menu-submenu-active .ant-menu-item-icon ,
 .ant-menu-item-open .ant-menu-item-icon ,
.ant-menu-submenu-open .ant-menu-item-icon ,
 .ant-menu-item-selected .ant-menu-item-icon ,
 .ant-menu-submenu-selected .ant-menu-item-icon{
filter:  brightness(0) invert(1) !important; 
}


/*common styles*/
.white-card{
  background: #FFFFFF;
  border: 1px solid #E6EBF1;
  border-radius: 12px;
}
.line{
  border: 1px solid #E6EBF1;
}



/*dashbaord styles*/

.card-heading{
  font-weight: 700;
  padding: 19px;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
}

.middleContainer{
  max-width: 100%;
  max-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}



/* Table Styles*/
.ant-table-thead .ant-table-cell {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 21px;
/* identical to box height */

text-align: justify;

/* secondary colour */

color: #1B75BC;
}



.ant-table-thead .ant-table-cell {
  background-color: white;
  height:65px;
}

.table-container{
  /* border: 1px solid red; */
  width: auto;
  margin: 1rem;
}
.buttons-container{
  width: 60%;
  margin-left:16px;
  display: flex;
  gap:15px;
  /* border: 1px solid red; */
  /* justify-content: space-between;  */
}
.col-normal{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;
/* identical to box height */

text-align: justify;
}
.col-special{
  font-weight: 600;
}

.ant-popover-inner-content{
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 8px 25px 0px 20px !important;
  /* padding: 12px 25px 0px 20px !important; */
}

/*pagination styles*/
.ant-pagination-item {
  border-radius:50% !important;
  
}
.ant-pagination-item-active,
.ant-pagination-item:focus-visible a {
  background-color: #1B75BC !important;
  
}
.ant-pagination-item-active a{
  color: #ffffff !important;
}
/*pagination style end*/

.flex-space-between{
  display: flex;
  justify-content: space-between;
}



/*Button Styles*/
.save-button{
  background: #473ADD;
  border-radius: 8px;
  /* border: 1px solid #473ADD; */
  border: none;
  color: white;
  padding: 6px 25px;
  margin-left: 16px;
  cursor:pointer;
}
.back-button{
  background: #FFFFFF;
/* stroke */
padding: 6px 25px;
border: 1px solid #E6EBF1;
border-radius: 8px;
cursor:pointer;
}

/*button style end*/




/*Modal Css*/
.ant-modal-content {
  border-radius: 12px !important;
  background: #FFFFFF !important;
  /* background: red; */
}
.ant-modal-header {
  border-radius: 12px !important;
}
.accept-modal{
  padding: 15px;
  text-align: center;
}
.ant-modal-title{
  font-weight: 600 !important;
font-size: 18px !important;
line-height: 22px !important;
} 
.modal-masg{
  font-style: normal;
font-weight: 700;
font-size: 18px;
margin-top: 15px;
line-height: 20px;
color: #1A3353;
}

.modal-user-info{
  font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 20px;
text-align: center;
color: rgba(26, 51, 83, 0.6);
}


.modal-buttons{
  margin-top: 20px;
  display: flex;
  gap: 20px;
justify-content: center;
}
.reject-modal{

}
/*modal css end*/





/*Status of tables*/



/*Transaction page icons change css after hover and active*/

.ant-tabs-tab img{
  margin-right: 10px;
}
.ant-tabs-tab-active .ant-tabs-tab-btn span img{
  filter: invert(36%) sepia(91%) saturate(610%) hue-rotate(167deg) brightness(92%) contrast(92%) !important;
 
}
.ant-tabs-tab:hover {
  filter: invert(36%) sepia(91%) saturate(610%) hue-rotate(167deg) brightness(92%) contrast(92%) !important;
}

.ql-toolbar.ql-snow{
  background: #F5F7FB;
  border-radius: 8px 8px 0px 0px;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-radius: 0px 0px 8px 8px;
}


/* .ant-btn:focus, .ant-btn:hover {
  color: black !important;
} */


.ant-dropdown-menu{
  border-radius: 8px !important;
  /* padding: 3px 13px !important; */
  padding: 0px 0px !important;
}
.ant-dropdown-menu-item{
  padding: 5px 14px !important;
}

.ant-dropdown-menu-item:hover{
  background: #EBF1FF !important;
}
.ant-dropdown-menu-submenu-title {
  padding-right: 62px !important;
}

.ant-dropdown-menu-sub{
  min-width: 150px;
}


/* .ant-table-cell{
  padding: 10px 10px !important;
} */

/* .ant-table-cell{
  padding: 15px 8px !important;
} */

.ant-table-tbody>tr>td{
  padding: 15px 8px !important;
}

.ant-image-img{
  object-fit: cover;
}

.show{
  visibility: visible;
}

.hide{
  visibility: hidden;
}