.login-container {
  width: 100%;
  display: flex;
  gap: 10%;
  /* jutify-content: center; */
  align-items: center;
  /* justify-content: center; */
}

.left-login > img{
  /* width: 115%;
  height: 660px;
  background-repeat: no-repeat;
  background-size: 100% 100%; */
  width: 111%;
  max-height: 100vh;
  height: auto;
  /* border:10px solid red; */
}

.left-login{
  /* border:1px solid blue; */
}

.right-login {
  width: 499px;
  /* height: 607px; */
  margin: 20px;
}
.login-box {
  padding: 60px;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.login-icon {
 
}
.sign-in-head {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  margin-top: 26px;
}
.signin-button {
  width: 363px;
  height: 40px;
  background: linear-gradient(
    90deg,
    #9dcb3b -48.83%,
    #4dae47 29.13%,
    #009648 104.82%
  );
  border-radius: 8px;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}
