.user-container {
  /* border: 1px solid red; */
}

/* Transactions CSS */

.transaction-container {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px;
  gap: 1rem;
}

.left-profile {
  width: 40%;
  height: 655px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
  /* overflow-x: hidden; */
  /* border: 1px solid red; */
  padding: 16px;
}
.tabs-container {
  width: 100%;
}

.profile-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  /* border: 1px solid red; */
  /* margin-left: 30%; */
}
.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height, or 120% */

  letter-spacing: 0.01em;

  color: #272c35;
}
.user-info {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height, or 120% */

  color: rgba(39, 44, 53, 0.8);
}

.profile-heading {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
}

.user-info-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  margin-top: 10px;
  /* or 21px */

  color: rgba(39, 44, 53, 0.8);
}

.basics {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}
.badge {
  background: rgba(221, 253, 182, 0.2);
  border-radius: 7px;
  padding: 8px;
}

/*Timeline css*/
.timeline-container {
  width: 100%;
  padding: 1rem;
}
.last-login {
  text-align: right;
  display: flex;
  flex-direction: column;
}
.timeline {
  padding: 1rem;
}

.text-style-ip-location {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  line-height: 5px;
}
.text-green {
  text-decoration-line: underline;
  color: #00ab6f;
}

.icon-img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #1b75bc;
  border: 1px solid #1b75bc;
  /* border-radius: 100px; */
}

.timelinw-text-group{
  /* border: 1px solid red; */
  margin-left: 10px;
}
.timeline-time{
  font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 15px;
color: #8A8A8A;
}
.timeline-title{
  font-weight: 500;
font-size: 16px;
line-height: 0px;
color: #272C35;
}
.ant-timeline-item-tail
{
  border-left: 2px solid #155B9F;
}

/* ADD SUGGESTION FORM*/
.add-suggestion-container {
  width: 100%;
  /* border:1px solid red; */
  display: flex;
  gap: 16px;
  padding: 24px;
}

.save-back-button {
  position: absolute;
  top: 551px;
  right: 25px;
}

.save-back-button-add-feature {
  /* position: absolute;
  top: 615px;
  left: 1075px; */
  float: right;
  margin-top: 15px;
}
.form-container {
  /* border: 1px solid red; */
  width: 100%;
  /* height: fit-content; */
    /* height: 200px; */
    /* min-height: 200px; */
  padding: 1.5rem;
}

.form-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: justify;

  /* secondary colour */

  color: #1b75bc;
}

.label-text {
  display: block;
  margin-top: 25px;
  margin-bottom: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: justify;

  /* text */

  color: #1a3353;
}
.input-box {
  /* width: 666px; */
  /* height: 40px; */
  border: 1px solid #e6ebf1;
  border-radius: 8px;
}

.CardLimit{
  display:flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  /* height: 15; */
}

.CardLimitBox{
  background-color: #ffffff;
  height:130px;
  width:32%;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
  padding-top: 8px;
  padding-left:16px;
  /* display: flex; */
  /* justify-content: space-between; */
  /* flex-direction: column; */
  
}

.flex{
  display:flex;
  flex-direction: row;
  align-items: center;
  margin-top: 26.5px;
}

.textCard{
  color: #212121;
font-size: 16px;
font-family: Poppins;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.textSmallCard{
  color: #212121;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}