.noti-card {
    min-width: 20%;
    min-height: 10%;
    /* max-height: max-content; */
    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.05));
    border-radius: 4px;
  }
  .noti-heading {
    margin-top: -16px;
    padding-top: 8px;
    width: 92%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .noti-title {
    font-family: "Inter";
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
  
    /* text title */
  
    color: #272c35;
  }
  .noti-clear {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
  
    /* secondary colour */
  
    color: #1b75bc;
  }
  .noti-list {
    /* padding: 0px; */
    margin-left: -14px;
    overflow-y: scroll;
    margin-top: 10px;
    max-height: 450px;
  }
  .single-noti {
    width: 100%;
    max-height: 130px;
    display: flex;
    align-items: flex-start;
    /* border: 1px solid red; */
  }
  .single-noti:hover {
    background: #e6fdff;
    box-shadow: 0px 1px 0px #e4e8ee;
  }
  .noti-desc {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */
  
    /* text title */
  
    color: #272C35;
  }
  .noti-time {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
  
    /* text caption */
  
    color: #8a8a8a;
  }
  

/*Profile card css*/
.profile-card{
    width: 100%;
  height: max-content;
  background: #FFFFFF;
  /* box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25); */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  font-family: 'Inter';
  font-style: normal;
  }
  /* .profile-menu-list{
    padding: 12px 12px;
  } */
.ant-notification-notice-close {
     margin: -10px !important;
     font-size: 19px !important;
}

.ant-popover-inner{
    border-radius: 8px !important;
    text-align: start !important;
    padding: 5px 0px 9px 0px !important; 
    /* popover if change then remove padding */
}
